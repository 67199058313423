import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Link as MuiLink,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import { format } from 'date-fns'
import debounce from 'lodash.debounce'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LoadingButton } from '~/components/Button/LoadingButton'
import DataNotFound from '~/components/DataNotFound'
import CustomModal from '~/components/Modal/CustomModal'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import AddGoogleTenantModal from './AddGoogleTenantModal'
import TenantActionButton from './TenantActionButton'
import { TenantHeadCells } from './TenantHeadCells'
import { useOverviewRemoveInactiveTenantMutation } from './mutation'
import {
  useCheckDomainPermissionMutation,
  useCheckTenantIntuneLicenceRevokedMutation,
  useViewTenantsQuery
} from './query'

const localization = 'pages.tenant.'

const TenantTableBody = ({ isLoading, tenants, removeInactiveTenant }) => {
  const { overviewUser } = useAdminPermission()

  const { mutate: checkDomainStatus, isLoading: isDomainChecking } =
    useCheckDomainPermissionMutation()
  const { mutate: checkIntuneRevoked, isLoading: isIntuneChecking } =
    useCheckTenantIntuneLicenceRevokedMutation()

  const copyValue = useCallback(e => {
    navigator.clipboard.writeText(e)
    enqueueSnackbar({
      message: 'Copied to clipboard',
      variant: 'info'
    })
  }, [])

  if (isLoading) {
    return <TableLoading colSpan={TenantHeadCells.length} />
  }

  const renderActionButtonCol = row => {
    return (
      <TenantActionButton
        row={row}
        onRemoveTenantClick={removeInactiveTenant}
        onCheckPermissionClick={id => checkIntuneRevoked(id)}
        onCheckDomainClick={id => checkDomainStatus(id)}
        isDomainChecking={isDomainChecking}
        isIntuneChecking={isIntuneChecking}
      />
    )
  }

  const renderTenantNameCol = row => (
    <>
      {overviewUser.tenants.canViewDetail ? (
        <Link to={`/overview/tenants/${row.id}`}>
          <MuiLink>{row.name}</MuiLink>
        </Link>
      ) : (
        <span>{row.name}</span>
      )}
    </>
  )

  return (
    <TableBody>
      {tenants.length === 0 && <DataNotFound colSpan={TenantHeadCells.length} />}
      {tenants.length > 0 &&
        tenants.map((row, index) => {
          let chipcolor = 'info'
          if (row?.serviceTenant?.active === true) {
            chipcolor = 'success'
          } else {
            chipcolor = 'error'
          }
          return (
            <TableRow hover key={index}>
              <TableCell>{renderTenantNameCol(row)}</TableCell>
              <TableCell>{row?.serviceTenant?.default_domain_name}</TableCell>
              <TableCell>{row?.serviceTenant?.initial_domain_name}</TableCell>
              <TableCell>{row?.serviceTenant?.hosted_domain}</TableCell>
              <TableCell>
                {row.serviceTenant && (
                  <Chip
                    label={row?.serviceTenant?.active.toString()}
                    color={chipcolor}
                    size='small'
                  />
                )}
              </TableCell>
              <TableCell>
                {row?.registration_link && (
                  <>
                    {row?.registration_token}
                    <IconButton
                      onClick={() => copyValue(row?.registration_link)}
                      sx={{ fontSize: '14px' }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
              <TableCell sx={{ width: '150px' }}>
                {row?.serviceTenant?.updated_at
                  ? format(new Date(row?.serviceTenant?.updated_at), 'dd-MM-yyyy')
                  : 'N/A'}
              </TableCell>
              <TableCell sx={{ width: '150px' }}>{renderActionButtonCol(row)}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

TenantTableBody.propTypes = {
  isLoading: PropTypes.bool,
  tenants: PropTypes.array,
  removeInactiveTenant: PropTypes.func.isRequired
}

TenantTableBody.defaultProps = {
  isLoading: false,
  tenants: []
}

const TenantTable = () => {
  const { t } = useTranslation()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [inCludeUser, setInCludeUser] = useState(false)
  const [azureTenantId, setAzureTenantId] = useState(0)
  const { handleSubmit } = useForm()

  const { data, isLoading } = useViewTenantsQuery({
    page,
    search,
    order,
    orderBy
  })

  const tenants = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )
  const { mutate: removeInactiveTenantMutate } = useOverviewRemoveInactiveTenantMutation()
  const onSearch = debounce(event => setSearchValue(event.target.value), 500)

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const removeInactiveTenant = useCallback((_, value) => {
    setAzureTenantId(value)
    setOpen(true)
  }, [])

  const handleCloseRemoveTenantModal = () => {
    setOpen(false)
  }
  const handleSubmitRemoveTenantModal = async () => {
    removeInactiveTenantMutate({ id: azureTenantId, includeUser: inCludeUser })
    setAzureTenantId(0)
    setOpen(false)
  }

  const handleChangeIncludeUser = event => {
    setInCludeUser(event.target.value)
  }

  return (
    <>
      <Paper elevation={1}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={3}>
            <AddGoogleTenantModal />
          </Grid>
          <Box sx={{ flexGrow: 0.01 }} />
          <Grid item xs={6} sm={3}>
            <SearchToolBar
              filterSearch={search}
              onChange={onSearch}
              placeholder='name_or_domain_name'
            />
          </Grid>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={TenantHeadCells}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              localization={localization}
            />
            <TenantTableBody
              isLoading={isLoading}
              tenants={tenants}
              removeInactiveTenant={removeInactiveTenant}
            />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={handleChangePage}
        />
      </Paper>

      <CustomModal open={open} onClose={handleCloseRemoveTenantModal}>
        <Typography variant='h6' sx={{ marginBottom: '10px' }}>
          {t('pages.tenant.confirmRemoveTenant')}
        </Typography>

        <form onSubmit={handleSubmit(handleSubmitRemoveTenantModal)}>
          <FormControl fullWidth>
            <InputLabel id='include-user-select-label'>{t('pages.tenant.includeUser')}</InputLabel>
            <Select
              labelId='include-user-select-label'
              id='include-user-select'
              value={inCludeUser}
              label='Include user'
              onChange={handleChangeIncludeUser}
            >
              <MenuItem value={false}>{t('pages.tenant.no')}</MenuItem>
              <MenuItem value>{t('pages.tenant.yes')}</MenuItem>
            </Select>
          </FormControl>
        </form>
        <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
          <LoadingButton onClick={handleCloseRemoveTenantModal}>
            {t('pages.tenant.cancel')}
          </LoadingButton>
          <LoadingButton onClick={handleSubmitRemoveTenantModal}>
            {t('pages.tenant.confirm')}
          </LoadingButton>
        </Stack>
      </CustomModal>
    </>
  )
}
export default TenantTable
