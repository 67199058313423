import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import debounce from 'lodash.debounce'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import OverviewRegisterSessionTable from './OverviewRegisterSessionTable'
import { useOrganisationListQuery, useRegisterSessionQuery } from './query'

const OverviewRegisterSession = () => {
  const { t } = useTranslation()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [search, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const [orgFilter, setOrgFilter] = useState('')
  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  const onSearch = debounce(event => setSearchValue(event.target.value), 500)

  const handleChangePage = useCallback((_, value) => {
    setPage(value)
  }, [])

  const { data, isLoading } = useRegisterSessionQuery({
    page,
    search,
    order,
    orderBy,
    external_id: orgFilter?.external_id
  })

  const { data: orgData } = useOrganisationListQuery()

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>{t('general.registerSessionOverview')}</Typography>
      </Breadcrumbs>
      <Divider />
      <Toolbar>
        <Grid container spacing={2} padding={1.5}>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={2}>
            <Autocomplete
              id='type'
              options={orgData || []}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={params => (
                <TextField {...params} label={t('pages.organisation.organisation')} />
              )}
              onChange={(event, newValue) => {
                setOrgFilter(newValue)
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <SearchToolBar
              filterSearch={search}
              onChange={onSearch}
              placeholder='serials_or_State'
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <OverviewRegisterSessionTable
        order={order}
        orderBy={orderBy}
        isLoading={isLoading}
        handleRequestSort={handleRequestSort}
        data={data}
      />
      <PaginationTable data={data} page={page} size='medium' handleChangePage={handleChangePage} />
    </>
  )
}

export default OverviewRegisterSession
