import { Button, Divider, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import PropTypes from 'prop-types'

import ConstructionIcon from '@mui/icons-material/Construction'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { alpha, styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useUserPermission } from '~/hooks/useUserPermission'

const StyledMenu = styled(props => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const DeviceActionButton = ({ row, onDeregisClick, onRepairClick, onIsRepairClick }) => {
  const { t } = useTranslation()
  const { userAbility } = useUserPermission()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const actionClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id='action-button-dropdown'
        aria-controls={open ? 'customized-action' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={actionClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('pages.device.action')}
      </Button>
      <StyledMenu
        id='customized-action'
        MenuListProps={{
          'aria-labelledby': 'action-button-dropdown'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={e => onDeregisClick(e, row.id)}
          disabled={
            ['DELETING', 'IMAGING'].includes(row.state) || !userAbility.devices.canBulkDeregister
          }
          disableRipple
        >
          <DeleteForeverIcon />
          {t('button.deRegister')}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={e => onRepairClick(e, row)}
          disabled={
            ['REPAIR', 'DELETING', 'ERROR', 'IMAGING', 'REGISTERING'].includes(row.state) ||
            !userAbility.devices.canRepair
          }
          disableRipple
        >
          <ConstructionIcon />
          {t('button.inRepair')}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={e => onIsRepairClick(e, row)}
          disabled={
            !['REPAIR', 'DELETING', 'IMAGING', ''].includes(row.state) ||
            !userAbility.devices.canRepair
          }
          disableRipple
        >
          <EditIcon />
          {t('button.isRepaired')}
        </MenuItem>
      </StyledMenu>
    </>
  )
}

DeviceActionButton.propTypes = {
  row: PropTypes.object.isRequired,
  onDeregisClick: PropTypes.func.isRequired,
  onRepairClick: PropTypes.func.isRequired,
  onIsRepairClick: PropTypes.func.isRequired
}

export default DeviceActionButton
