import { enqueueSnackbar } from 'notistack'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { overviewRoles, overviewUsersDetail } from '~/api'
import { setUserDetailInformation } from '~/store/user/slice'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useUserQuery = id => {
  const dispatch = useDispatch()
  return useQuery(
    'overview_user_info',
    async () => {
      const { data } = await overviewUsersDetail(id)
      return data.data
    },
    {
      onSuccess: data => {
        dispatch(setUserDetailInformation(data))
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false,
      enabled: !!id
    }
  )
}

export const useGetAllRolesQuery = params => {
  return useQuery('overview_roles', async () => {
    const p = removeEmptyValue(params)
    const { data } = await overviewRoles(p)
    return data.data
  })
}
