import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { adminSyncDevices, syncDevices } from '~/api'
import {
  updateGoogleCustomerId,
  updateSchoolTenantId,
  updateSchoolToken,
  updateServiceTenantToken
} from '~/api/admin'
import overviewOrganisationApi from '~/api/overviewOrganisationApi'
import { parseApiErrorMessage, showErrorMessage } from '~/utils/helpers'

export const useSyncDeviceMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async tenantId => {
      const { data } = await syncDevices(tenantId)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message,
          variant: 'success',
          autoHideDuration: 2000
        })
        queryClient.invalidateQueries('profile')
        queryClient.invalidateQueries('app_connection')
        queryClient.invalidateQueries('last_connection')
      }
    }
  )
}

export const useOverviewSyncDeviceMutation = tenantId => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const { data } = await adminSyncDevices(tenantId)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message,
          variant: 'success',
          autoHideDuration: 2000
        })
        queryClient.invalidateQueries('profile')
        queryClient.invalidateQueries('app_connection')
        queryClient.invalidateQueries('last_connection')
      }
    }
  )
}

export const useUpdateGoogleCustomerIdMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id, customerId }) => {
      const { data } = await updateGoogleCustomerId(id, customerId)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message,
          variant: 'success'
        })
        queryClient.invalidateQueries('tenant_details')
      },
      onError: e => {
        enqueueSnackbar({
          message: parseApiErrorMessage(e),
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useUpdateSchoolTokenMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async payload => {
      const { data } = await updateSchoolToken(payload.id, payload.token)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated token',
          variant: 'success'
        })

        queryClient.invalidateQueries('tenant_details')
      },
      retry: false
    }
  )
}

export const useUpdateGoogleTokenMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async payload => {
      const { data } = await updateServiceTenantToken(payload.id, payload.token)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated token',
          variant: 'success'
        })

        queryClient.invalidateQueries('tenant_details')
      },
      retry: false
    }
  )
}

export const useUpdateSchoolTenantIdMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async payload => {
      const { data } = await updateSchoolTenantId(payload)
      return data.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.message || 'School added to tenant successfully',
          variant: 'success'
        })

        queryClient.invalidateQueries('tenant_details')
        queryClient.invalidateQueries('overview_orgs_listing')
      },
      onError: showErrorMessage,
      retry: false
    }
  )
}

export const useUpdateIntuneEnrollTriggerMutation = organisationId => {
  const queryClient = useQueryClient()
  return useMutation(
    async payload => {
      const { data } = await overviewOrganisationApi.updateintuneEnrollTrigger(
        organisationId,
        payload
      )
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully updated intune enroll trigger',
          variant: 'success'
        })
        queryClient.invalidateQueries('organisation_detail', organisationId)
      },
      onError: showErrorMessage,
      retry: false
    }
  )
}
