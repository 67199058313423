import {
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import TenantIcon from '~/components/layout/IconComponent'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import { RegisterSessionHeadCells } from './RegisterSessionHeadCells'

const localization = 'pages.device.registerSession.'

const OverviewRegisterSessionBody = ({ isLoading, sessions }) => {
  const { overviewUser } = useAdminPermission()

  if (isLoading) {
    return <TableLoading colSpan={RegisterSessionHeadCells.length} />
  }

  const renderSerialCol = row => (
    <>
      {overviewUser.devicesRegisterSession.canViewDetail ? (
        <MuiLink to={'/overview/devices-register-sessions/' + row.id} component={Link}>
          {row.serial}
        </MuiLink>
      ) : (
        <>{row.serial}</>
      )}
    </>
  )

  return (
    <TableBody>
      {sessions.length === 0 && <DataNotFound colSpan={RegisterSessionHeadCells.length} />}
      {sessions.length > 0 &&
        sessions.map((row, index) => {
          let chipcolor = 'default'
          if (row.is_completed) {
            chipcolor = 'success'
          }

          let chipcolorState = 'info'
          if (
            [
              'ERROR',
              'INTUNE_IMPORT_ERROR',
              'DEVICE_IN_OTHER_TENANT',
              'CASE_IN_REPAIR_ERROR'
            ].includes(row.state)
          ) {
            chipcolorState = 'error'
          }

          if (['REGISTERED', 'CLAIMED', 'INTUNE_IMPORT_SUCCESS'].includes(row.state)) {
            chipcolorState = 'success'
          }

          if (['DELETED', 'RE_REGISTER_DEVICE_TO_TENANT', 'DE_REGISTERED'].includes(row.state)) {
            chipcolorState = 'warning'
          }

          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align='left'>
                <TenantIcon item={row} />
              </TableCell>
              <TableCell align='left'>
                <Typography>{renderSerialCol(row)}</Typography>
              </TableCell>
              <TableCell align='left'>{row?.organisation?.name}</TableCell>
              <TableCell align='left'>{row.success_code}</TableCell>
              <TableCell align='left'>
                <Chip
                  label={row.state}
                  color={chipcolorState}
                  size='small'
                  sx={{
                    paddingTop: 0.5
                  }}
                />
              </TableCell>
              <TableCell align='left'>
                <Chip
                  label={row.is_completed ? 'completed' : 'incomplete'}
                  color={chipcolor}
                  size='small'
                />
              </TableCell>
              <TableCell align='left'>{row.message}</TableCell>
              <TableCell align='left'>{row.created_at}</TableCell>
              <TableCell align='left'>{row.updated_at}</TableCell>
            </TableRow>
          )
        })}
    </TableBody>
  )
}

OverviewRegisterSessionBody.defaultProps = {
  isLoading: false,
  sessions: []
}

OverviewRegisterSessionBody.propTypes = {
  isLoading: PropTypes.bool,
  sessions: PropTypes.array
}

const OverviewRegisterSessionTable = ({ order, orderBy, isLoading, handleRequestSort, data }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
        <TableHeader
          headCells={RegisterSessionHeadCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          localization={localization}
        />
        <OverviewRegisterSessionBody isLoading={isLoading} sessions={data?.data} />
      </Table>
    </TableContainer>
  )
}

OverviewRegisterSessionTable.defaultProps = {
  order: 'asc',
  orderBy: '',
  isLoading: false,
  data: []
}

OverviewRegisterSessionTable.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  handleRequestSort: PropTypes.func
}

export default OverviewRegisterSessionTable
