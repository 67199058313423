import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '~/utils/helpers'

const localization = 'pages.adminOverview.users.detail'

const UserAttributes = ({ data }) => {
  const { t } = useTranslation()
  const selectUser = data

  return (
    <Grid item xs={12} md={6} lg={8}>
      <Stack direction='column' spacing={3}>
        <Card>
          <CardContent>
            <Box
              sx={{
                width: '100%',
                bgcolor: 'background.paper'
              }}
            >
              <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems='center'>
                  <Stack direction='column'>
                    <Grid item xs>
                      <Typography gutterBottom variant='h4' component='div'>
                        {t(`${localization}.userData`)}
                      </Typography>
                    </Grid>
                    <Stack direction='row' spacing={10}>
                      <Grid item>
                        <Typography gutterBottom variant='h6' component='div'>
                          {t(`${localization}.email`)}
                        </Typography>
                        <Typography gutterBottom variant='h8' component='div'>
                          {selectUser?.email}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography gutterBottom variant='h6' component='div'>
                          {t(`${localization}.gender`)}
                        </Typography>
                        <Typography gutterBottom variant='h8' component='div'>
                          {selectUser?.gender}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography gutterBottom variant='h6' component='div'>
                          {t(`${localization}.emailVerifyAt`)}
                        </Typography>
                        <Typography gutterBottom variant='h8' component='div'>
                          {formatDateTime(selectUser?.email_verified_at)}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Stack>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Grid>
  )
}

UserAttributes.propTypes = {
  data: PropTypes.object
}

export default UserAttributes
