import { Box, Breadcrumbs, Divider, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import FullPageLoading from '~/components/FullPageLoading'
import { useSnackbar } from '~/hooks/useSnackbar'
import { selectSnackbar } from '~/store/snackbar/selector'
import { unsetSnackbar } from '~/store/snackbar/slice'
import { useAzureDeviceDetailQuery } from '../../query'
import { useTranslation } from 'react-i18next'

const AdminAzureDevice = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const localization = 'pages.device.detail'

  const { data, isLoading } = useAzureDeviceDetailQuery(id)
  const snackbarData = useSelector(selectSnackbar)
  const { showSnackbar, snackbar } = useSnackbar()
  const dispatch = useDispatch()

  useEffect(() => {
    if (snackbarData) {
      showSnackbar(snackbarData.message, snackbarData.type)
    }
    return () => {
      dispatch(unsetSnackbar({}))
    }
  })

  if (isLoading) {
    return <FullPageLoading />
  }

  return (
    <>
      {snackbar}
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to='/overview/devices'>{t(`${localization}.deviceOverview`)}</NavLink>
        </Typography>
        <Typography>{data?.data?.serial}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant='h6'>{t(`${localization}.deviceInformation`)}</Typography>
      {Object.keys(data.data).map(key => {
        return (
          <div key={key}>
            {key} :{' '}
            {data.data[key] === true ? 'true' : data.data[key] === false ? 'false' : data.data[key]}
          </div>
        )
      })}
      <Box sx={{ wordBreak: 'break-word' }} key={data?.hash}>
        hash: {data?.hash}
      </Box>
    </>
  )
}
export default AdminAzureDevice
