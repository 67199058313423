import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import { useUpdateRoleMutation } from '../Detail/mutate'
import { useGetAllRolesQuery } from '../Detail/query'

const UserRole = ({ localization, canChangeRole }) => {
  const [isChanged, setIsChanged] = useState(false)

  const selectedUser = useSelector(selectUserDetailInformation)
  const [selectedRole, setSelectedRole] = useState(selectedUser?.role[0])
  const { data: roles, isLoading } = useGetAllRolesQuery({ user_account: true })

  const onChangeRole = useCallback(event => {
    setIsChanged(true)
    setSelectedRole(event.target.innerHTML)
  }, [])

  const { t } = useTranslation()
  const { mutate: updateRole, isLoading: isSaving } = useUpdateRoleMutation({})

  if (isLoading) {
    return 'Loading...'
  }

  const handleUpdateRole = () => {
    updateRole(selectedRole)
    setIsChanged(false)
  }

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Typography gutterBottom variant='body1'>
          CHANGE {t(`${localization}.role`).toUpperCase()}
        </Typography>

        <Grid container spacing={2}>
          {roles && (
            <Autocomplete
              id='role'
              name='role'
              freeSolo
              value={selectedRole}
              options={roles.map(option => option.name)}
              onChange={e => {
                setIsChanged(true)
                setSelectedRole(event.target.innerHTML)
              }}
              sx={{ mt: 2, ml: 2, width: '70%' }}
              renderInput={params => (
                <TextField {...params} label={t(`${localization}.role`)} onChange={onChangeRole} />
              )}
              disabled={!canChangeRole}
            />
          )}
          <Grid item md={3}>
            <LoadingButton
              variant='contained'
              size='large'
              disabled={!isChanged}
              loading={isSaving}
              sx={{
                textTransform: 'none',
                backgroundColor: '#76B72A',
                height: '100%'
              }}
              type='submit'
              onClick={handleUpdateRole}
            >
              {t(`${localization}.save`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

UserRole.propTypes = {
  localization: PropTypes.string,
  canChangeRole: PropTypes.bool
}

UserRole.defaultProps = {
  canChangeRole: true
}

export default UserRole
