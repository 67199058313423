import { Check } from '@mui/icons-material'
import { Chip, TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { formatDateTime } from '~/utils/helpers'
import { GoogleHeadCells } from '../UserTenantHeadCell'

const UserBodyGoogleDomain = ({ isLoading, selectUser, currentTenantId }) => {
  if (isLoading) {
    return <TableLoading colSpan={GoogleHeadCells.length} />
  }

  if (selectUser?.tenants?.length < 1) {
    return (
      <TableBody>
        <DataNotFound colSpan={GoogleHeadCells.length} />
      </TableBody>
    )
  }

  return (
    <TableBody>
      {selectUser?.tenants?.map((row, index) => {
        let chipcolor = 'info'
        if (row?.service_tenant?.active === true) {
          chipcolor = 'success'
        } else {
          chipcolor = 'error'
        }
        return (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0
              }
            }}
          >
            <TableCell>{row.name || '---'}</TableCell>
            <TableCell>{row?.hosted_domain || '---'}</TableCell>
            <TableCell>
              {row?.service_tenant && (
                <Chip
                  label={row?.service_tenant?.active.toString()}
                  color={chipcolor}
                  size='small'
                />
              )}
            </TableCell>
            <TableCell>{row.created_at ? formatDateTime(row.created_at) : '---'}</TableCell>
            <TableCell>
              {row?.id === currentTenantId ? <Check sx={{ color: '#008000' }} /> : ''}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}

UserBodyGoogleDomain.defaultProps = {
  isLoading: false
}

UserBodyGoogleDomain.propTypes = {
  isLoading: PropTypes.bool,
  selectUser: PropTypes.object,
  currentTenantId: PropTypes.string
}

export default UserBodyGoogleDomain
