import { enqueueSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminLogin } from '~/api'
import { setApiAdminAuthToken } from '~/api/adminBase'
import { ADMIN_OVERVIEW } from '~/constants/Routes'
import { KEY_ADMIN_AUTH_TOKEN } from '~/constants/constants'
import { setAdmin, setAdminPermissions, setIsAdminAuthenticating } from '~/store/adminAuth/slice'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useAdminAuthenticateMutation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ['admin_login'],
    async payload => {
      return await adminLogin(payload)
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully login',
          variant: 'success'
        })

        localStorage.setItem(KEY_ADMIN_AUTH_TOKEN, data.data.token)
        setApiAdminAuthToken(data.data.token)
        dispatch(setAdmin(data.data))
        dispatch(setIsAdminAuthenticating(false))
        const permissions = data.data?.admin?.permissions?.reduce((acc, cur) => {
          return [...acc, cur.name]
        }, [])
        dispatch(setAdminPermissions(permissions || []))
        navigate(ADMIN_OVERVIEW)
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
        dispatch(setIsAdminAuthenticating(false))
      }
    }
  )
}
