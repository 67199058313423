import { format } from 'date-fns'
import { enqueueSnackbar } from 'notistack'

export const removeEmptyValue = obj => {
  const tmp = { ...obj }

  Object.keys(obj).forEach(k => {
    if (!obj[k] || String(obj[k]).trim().length === 0) {
      delete tmp[k]
    }
  })

  return tmp
}

export const parseApiErrorMessage = err => {
  if (err === null) {
    return ''
  }

  if (err.response?.data?.errors) {
    const errors = err.response.data.errors
    // parse laravel validation error message
    return Object.keys(errors)
      .map(key => errors[key])
      .map(item => {
        if (Array.isArray(item) && item.length > 0) {
          return item[0]
        }

        return item
      })
      .join('\n')
  }

  if (err.response?.data?.message) {
    return err.response.data.message
  }

  return err.message
}

export const showErrorMessage = error => {
  const message = parseApiErrorMessage(error)
  enqueueSnackbar({
    message,
    variant: 'error'
  })
}

export const formatDateTime = (dateTime, toFormat = 'yyyy-MM-dd HH:mm:ss') => {
  return dateTime ? format(new Date(dateTime), toFormat) : 'N/A'
}

export const capitalize = str => {
  if (!str) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getUserStatusColor = status => {
  switch (status) {
    case 'pending':
      return 'info'
    case 'rejected':
      return 'error'
    case 'active':
      return 'success'
    case 'invited':
      return 'primary'
    case 'disabled':
      return 'warning'
    default:
      return 'default'
  }
}

export const isOverviewPage = window.location.href.indexOf('/overview') > -1
