import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import { addExtraUserToTenant } from '~/api'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useAddExtraUserToTenantMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async payload => {
      const { data } = await addExtraUserToTenant(payload)
      return data
    },
    {
      onSuccess: data => {
        const message = data?.message ?? 'Successfully added user to tenant'
        enqueueSnackbar({
          message,
          variant: 'success'
        })

        queryClient.invalidateQueries('users_overview')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      }
    }
  )
}
