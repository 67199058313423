import { Check, Error } from '@mui/icons-material'
import TokenIcon from '@mui/icons-material/Token'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomModal from '~/components/Modal/CustomModal'
import { useAdminPermission } from '~/hooks/useAdminPermission'
import HealthCheckBox from '../HealthCheckBox'
import { useUpdateGoogleTokenMutation, useUpdateSchoolTokenMutation } from '../mutation'

const RowInfo = ({ row }) => {
  let tokenValid = Boolean(row.active)
  if ('google_zte_token_status' in row) {
    tokenValid = Boolean(row.google_zte_token_status)
  }
  const externalId = row.external_id

  let message = ''
  if (externalId) {
    message = ` (Token: ${
      row.pre_provisioning_token ? row.pre_provisioning_token : 'N/A'
    } External ID: ${externalId})`
  } else {
    message = ` Domain token: ${row.pre_provisioning_token ? row.pre_provisioning_token : 'N/A'}`
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }} key={row.id}>
      {tokenValid ? (
        <Check sx={{ color: '#76B72A', mr: 1 }} />
      ) : (
        <Error sx={{ color: 'red', mr: 1 }} />
      )}
      <Box>
        {externalId && <Typography display='inline'>{row.name}</Typography>}
        <Typography display='inline' sx={{ fontWeight: 'bold' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

RowInfo.propTypes = {
  row: PropTypes.object.isRequired
}

const localization = 'pages.tenant.'
// tenant must be Google Tenant
const ZTETokenSection = ({ tenant }) => {
  const { t } = useTranslation()

  // Constants
  const serviceTenant = tenant.serviceTenant
  const schools = tenant.schools || []
  const hasZteToken =
    Boolean(serviceTenant?.pre_provisioning_token) ||
    schools.some(school => Boolean(school.pre_provisioning_token))

  // States
  const [openToken, setOpenToken] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState({ label: '', id: '' })
  const [token, setToken] = useState('')
  const [domainToken, setDomainToken] = useState(serviceTenant.pre_provisioning_token || '')
  const [tabValue, setTabValue] = useState(0)
  const [confirm, setConfirm] = useState(false)
  const [schoolConfirm, setSchoolConfirm] = useState(false)

  const { overviewUser } = useAdminPermission()

  // Mutations
  const { mutate: updateOrganizationToken, isLoading: isUpdatingSchoolToken } =
    useUpdateSchoolTokenMutation()
  const { mutate: updateDomainToken, isLoading: isUpdatingDomainToken } =
    useUpdateGoogleTokenMutation()

  // Handlers
  const saveDomaintToken = () => {
    updateDomainToken(
      { id: serviceTenant?.id, token: domainToken },
      {
        onSettled: () => setOpenToken(false)
      }
    )
  }
  const handleSaveDomaintToken = () => {
    if (!domainToken) {
      setConfirm(true)
    } else {
      saveDomaintToken()
    }
  }
  const handleConfirmClose = () => {
    setConfirm(false)
    setSchoolConfirm(false)
  }
  const handleConfirm = () => {
    if (schoolConfirm) {
      saveSchoolToken()
    } else if (confirm) {
      saveDomaintToken()
    }
    handleConfirmClose()
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const handleSaveSchoolToken = () => {
    if (!token) {
      setSchoolConfirm(true)
    } else {
      saveSchoolToken()
    }
  }
  const saveSchoolToken = () => {
    updateOrganizationToken(
      { id: selectedSchool?.id, token },
      {
        onSettled: () => setOpenToken(false)
      }
    )
  }

  const isAllTokenValid = useMemo(
    () => serviceTenant.active && schools.every(school => school.google_zte_token_status),
    [serviceTenant, schools]
  )

  // Extra info
  const domainInfo = <RowInfo key={serviceTenant.id} row={serviceTenant} />
  const schoolInfo = schools.map(school => <RowInfo key={school.id} row={school} />)
  const extraInfo = [domainInfo, ...schoolInfo]

  // Effects
  useEffect(() => {
    setToken(selectedSchool?.pre_provisioning_token || '')
  }, [selectedSchool])

  return (
    <>
      <HealthCheckBox
        icon={<TokenIcon />}
        title={t('pages.healthCheck.zteToken') + ` - Domain: ${serviceTenant.hosted_domain}`}
        data={{ is_success: isAllTokenValid }}
        extraInfo={extraInfo}
      >
        <LoadingButton
          onClick={() => setOpenToken(true)}
          sx={{ mt: 1, mr: 1 }}
          variant='contained'
          disabled={!overviewUser.tenants.canCheckStatus}
        >
          {t('pages.tenant.update')}
        </LoadingButton>
      </HealthCheckBox>

      <Dialog open={confirm || schoolConfirm} onClose={handleConfirmClose}>
        <DialogTitle>{t(`${localization}beforeDelete`)}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmClose} color='primary'>
            {t(`${localization}cancel`)}
          </Button>
          <Button onClick={handleConfirm} color='error'>
            {t(`${localization}confirm`)}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomModal title='Add ZTE Token' open={openToken} onClose={() => setOpenToken(false)}>
        <Box>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label='Update School Token' />
            <Tab label='Update Domain Token' />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Autocomplete
                sx={{
                  mt: 2
                }}
                fullWidth
                options={schools.map(option => ({
                  label: option.name + ' - ' + option.external_id,
                  pre_provisioning_token: option.pre_provisioning_token,
                  id: option.id
                }))}
                value={selectedSchool}
                renderInput={params => <TextField {...params} label='School' />}
                onChange={(e, value) => setSelectedSchool(value)}
              />
              <TextField
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                id='zte_token'
                label='ZTE Token'
                variant='outlined'
                value={token}
                onChange={e => setToken(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <LoadingButton
                onClick={() => setOpenToken(false)}
                sx={{ mt: 1, mr: 1 }}
                variant='contained'
              >
                {t('pages.tenant.cancel')}
              </LoadingButton>
              <LoadingButton
                onClick={handleSaveSchoolToken}
                sx={{ mt: 1, mr: 1 }}
                variant='contained'
                disabled={isUpdatingSchoolToken || (!selectedSchool?.id && !token)}
              >
                {t('pages.tenant.save')}
              </LoadingButton>
            </>
          )}
          {tabValue === 1 && (
            <>
              <TextField
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                id='service_tenant_token'
                label='Service Tenant Token'
                variant='outlined'
                value={domainToken}
                onChange={e => setDomainToken(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <LoadingButton
                onClick={() => setOpenToken(false)}
                sx={{ mt: 1, mr: 1 }}
                variant='contained'
              >
                {t('pages.tenant.cancel')}
              </LoadingButton>
              <LoadingButton
                onClick={handleSaveDomaintToken}
                sx={{ mt: 1, mr: 1 }}
                variant='contained'
                disabled={isUpdatingDomainToken}
              >
                {t('pages.tenant.save')}
              </LoadingButton>
            </>
          )}
        </Box>
      </CustomModal>
    </>
  )
}

ZTETokenSection.propTypes = {
  tenant: PropTypes.object.isRequired
}

export default ZTETokenSection
